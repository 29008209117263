body {
    background: #f4f6fa;
}

ins, del {
    text-decoration: none;
}

ins {
    background: #dcffe4;
}

.theme-dark ins {
    background: #2fb344;
}

del {
    background: #ffdce0;
}

.theme-dark del {
    background: #a60f0f;
}

textarea:focus {
    box-shadow: none !important ;
    border: none
}

.flatpickr-current-month select.flatpickr-monthDropdown-months {
    -webkit-appearance: none;
    border-radius: 5px;
    padding: 5px;
}

.table td.fit {
    white-space: nowrap;
    width: 1%;
}

.cursor-pointer {
    cursor: pointer;
}